<template lang="">
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      ok-title="Lưu"
      cancel-title="Hủy bỏ"
      centered
      :title="title"
      no-close-on-backdrop
      @ok="handleCreateEdit"
      @hidden="resetData"
    >
      <validation-observer ref="rule">
        <b-form>
          <b-form-group>
            <label>Mã thiết bị <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="code"
              rules="required"
              :custom-messages="code"
            >
              <b-form-input
                v-model="dataInput.code"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label>Tên thiết bị <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
              :custom-messages="name"
            >
              <b-form-input
                v-model="dataInput.name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label>Đơn vị tính</label>
            <b-form-input
              v-model="dataInput.unit"
            />
          </b-form-group>

          <b-form-group>
            <label for="code">Loại thiết bị <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="types"
              rules="required"
              :custom-messages="types"
            >
              <v-select
                v-model="dataInput.equipmentType"
                :reduce="value => value.enName"
                label="vnName"
                :options="EquipmentType || []"
                placeholder="Lựa chọn loại thiết bị"
              >
                <template
                  slot="option"
                  slot-scope="option"
                >
                  {{ option.vnName }}
                </template>
                <template #selected-option-container="{ option }">
                  <div class="vs__selected">
                    {{ option.vnName }}
                  </div>
                </template>
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>

import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    dataDetailEquipment: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      required,
      code: {
        required: 'Mã thiết bị là bắt buộc',
      },
      types: {
        required: 'Loại thiết bị là bắt buộc',
      },
      name: {
        required: 'Tên thiết bị là bắt buộc',
      },
      EquipmentType: [
        {
          vnName: 'Thiết bị nâng',
          enName: 'LiftingEquipment',
        },
        {
          vnName: 'Thiết bị áp lực',
          enName: 'PressureEquipment',
        },
        {
          vnName: 'Công trình vui chơi công cộng',
          enName: 'PublicAmusementFacilities',
        }, {
          vnName: 'Khác',
          enName: 'Another',
        },
      ],
      dataInput: {
        code: '',
        name: '',
        unit: '',
        equipmentType: '',
      },
    }
  },
  watch: {
    dataDetailEquipment(val) {
      if (val && this.type === 'edit') {
        this.dataInput = {
          code: this.dataDetailEquipment.code,
          name: this.dataDetailEquipment.name,
          unit: this.dataDetailEquipment.unit,
          equipmentType: this.dataDetailEquipment.equipmentType,
        }
      }
    },
  },
  methods: {
    handleCreateEdit(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateEdit', this.dataInput)
        }
      })
    },
    resetData() {
      this.dataInput = {
        code: '',
        name: '',
        unit: '',
        equipmentType: '',
      }
    },
  },
}
</script>
<style lang="scss">
  .btn-down {
    display: grid;
    margin-bottom: 15px;
    align-items: end;
  }
</style>
