var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":_vm.id,"cancel-variant":"outline-primary","ok-title":"Lưu","cancel-title":"Hủy bỏ","centered":"","title":_vm.title,"no-close-on-backdrop":""},on:{"ok":_vm.handleCreateEdit,"hidden":_vm.resetData}},[_c('validation-observer',{ref:"rule"},[_c('b-form',[_c('b-form-group',[_c('label',[_vm._v("Mã thiết bị "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"code","rules":"required","custom-messages":_vm.code},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.code),callback:function ($$v) {_vm.$set(_vm.dataInput, "code", $$v)},expression:"dataInput.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("Tên thiết bị "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"name","rules":"required","custom-messages":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.name),callback:function ($$v) {_vm.$set(_vm.dataInput, "name", $$v)},expression:"dataInput.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("Đơn vị tính")]),_c('b-form-input',{model:{value:(_vm.dataInput.unit),callback:function ($$v) {_vm.$set(_vm.dataInput, "unit", $$v)},expression:"dataInput.unit"}})],1),_c('b-form-group',[_c('label',{attrs:{"for":"code"}},[_vm._v("Loại thiết bị "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"types","rules":"required","custom-messages":_vm.types},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (value) { return value.enName; },"label":"vnName","options":_vm.EquipmentType || [],"placeholder":"Lựa chọn loại thiết bị"},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.vnName)+" ")]}},{key:"selected-option-container",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"vs__selected"},[_vm._v(" "+_vm._s(option.vnName)+" ")])]}},{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataInput.equipmentType),callback:function ($$v) {_vm.$set(_vm.dataInput, "equipmentType", $$v)},expression:"dataInput.equipmentType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }