<template>
  <div
    id="users"
    class="page-container-table"
  >
    <!--phần header-->
    <button-all-header
      :contentBtnAdd="'Thêm thiết bị'"
      :arrayExcel="arrayExcel"
      :showBtnMultiDelete="showBtnMultiDelete"
      @clickDelete="deleteItems"
      @clickDowloadSample="downloadExampleFile"
      @clickExportExcel="downloadExportFile"
      @importFile="importFileExcel"
      @clickAdd="showModalCreate"
      @search="search($event)"
    />

    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <!-- Slot: Table Column -->
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span
          v-if="props.column.label ==='CodeDevice'"
          class="text-nowrap"
        >
          MÃ THIẾT BỊ
        </span>
        <span
          v-else-if="props.column.label ==='Name'"
          class="text-nowrap"
        >
          TÊN THIẾT BỊ
        </span>
        <span
          v-else-if="props.column.label ==='Unit'"
          class="text-nowrap"
        >
          ĐƠN VỊ TÍNH
        </span>
        <span
          v-else-if="props.column.label ==='ActionFunction'"
          class="text-nowrap"
        >
          CHỨC NĂNG
        </span>
        <span v-else>
          {{ props.column.label }}
        </span>
      </template>

      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: MÃ THIẾT BỊ-->
        <span v-if="props.column.field === 'CodeDevice'">
          <b-badge variant="light-primary">
            {{ props.row.code }}
          </b-badge>
        </span>

        <!-- Column: TÊN THIẾT BỊ-->
        <span v-else-if="props.column.field === 'Name'">
          <span class="text-nowrap">{{ props.row.name }}</span>
        </span>

        <!-- Column: ĐƠN VỊ TÍNH -->
        <span v-else-if="props.column.field === 'Unit'">
          <span class="text-nowrap">{{ props.row.unit }}</span>
        </span>

        <!-- Column:Chức năng -->
        <span v-else-if="props.column.field === 'ActionFunction'">
          <span
            @click="showModalEdit(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Chỉnh sửa'"
              icon="EditIcon"
              size="18"
              class="text-body"
            />
          </span>
          <span
            class="ml-2"
            @click="deleteItem(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Xóa'"
              icon="Trash2Icon"
              size="18"
              class="text-body"
            />
          </span>
        </span>
      </template>
    </vue-good-table>

    <!-- pagination -->
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="pageChange"
    />

    <!-- modal xóa người dùng-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />
    <!-- thêm mới modal  -->
    <modal-create
      :id="modalIdCreate"
      :dataDetailEquipment="dataDetailEquipment"
      :type="modalType"
      :title=" modalType === 'add'? 'Thêm thiết bị' : 'Chỉnh sửa thiết bị'"
      @handleCreateEdit="handleCreateEdit"
    />
  </div>
</template>

<script>
import { formatFullName } from '@core/utils/filter'
import { mapActions, mapGetters } from 'vuex'
import {
  BBadge,
  BMedia,
  BDropdown,
  BDropdownItem,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'

import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ModalCreate from './components/ModalCreate.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    BBadge,
    BMedia,
    MyPagination,
    BDropdown,
    BDropdownItem,
    ConfirmModal,
    BButton,
    VBTooltip,
    ModalCreate,
    ButtonAllHeader,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      formatFullName,
      columns: [
        {
          label: 'CodeDevice',
          field: 'CodeDevice',
          sortable: false,
        },
        {
          label: 'Name',
          field: 'Name',
          sortable: false,
        },
        {
          label: 'Unit',
          field: 'Unit',
          sortable: false,
        },
        {
          label: 'ActionFunction',
          field: 'ActionFunction',
          sortable: false,
          width: '150px',
        },
      ],
      currentPage: 0,
      urlQuery: {
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal',
      modalContent: '',
      showBtnMultiDelete: false,
      deleteIds: [],
      isActive: false,
      userId: '',
      modalIdCreate: 'modalIdCreate',
      dataList: [],
      totalRecord: 0,
      modalType: '',
      equipmentId: null,
      dataDetailEquipment: {},
      arrayExcel: [
        'code',
        'name',
        'unit',
        'equipmentType',
      ],
    }
  },
  created() {
    this.fetchData(this.urlQuery)
  },
  methods: {
    ...mapActions('equipment', [
      'downloadExportFile',
      'downloadExampleFile',
      'getApiExcel',
    ]),

    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xóa người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_EQUIPMENT, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // click show modal thêm
    showModalCreate() {
      this.modalType = 'add'
      this.$bvModal.show(this.modalIdCreate)
    },
    // click show modal sửa
    async showModalEdit(id) {
      this.modalType = 'edit'
      this.equipmentId = id
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_EQUIPMENT}${this.equipmentId}`)
      this.dataDetailEquipment = data
      this.$bvModal.show(this.modalIdCreate)
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    async handleCreateEdit(val) {
      const payload = {
        ...val,
      }
      if (this.modalType === 'edit') {
        this.$showAllPageLoading()
        await axiosApiInstance.put(ConstantsApi.EDIT_EQUIPMENT, { ...payload, id: this.equipmentId }).then(response => {
          if (response.status === 200) {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast('Sửa thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.$bvModal.hide(this.modalIdCreate)
          }
          this.fetchData(this.urlQuery)
        }).catch(err => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast(err.response.data.errors[0], {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      }

      if (this.modalType === 'add') {
        this.$showAllPageLoading()
        await axiosApiInstance.post(ConstantsApi.CREATE_EQUIPMENT, payload).then(response => {
          if (response.status === 200) {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast('Thêm mới thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.$bvModal.hide(this.modalIdCreate)
          }
          this.fetchData(this.urlQuery)
        }).catch(err => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast(err.response.data.errors[0], {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      }
    },

    // Fetch data
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.API_EQUIPMENT, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },

    // lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchData(this.urlQuery)
      }
    },

    // import file exel
    async importFileExcel(event) {
      if (event) {
        this.getValidData(event)
      }
    },
    async getValidData(dataInput) {
      const model = {
        listExcel: dataInput,
        isValidate: true,
      }
      await this.getApiExcel(model)
      await this.$router.push({ name: 'import-equipment-management' })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

#users {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
}
</style>
